// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify(
  {
    theme: {
      defaultTheme: "customLightTheme",
      themes: {
        customLightTheme: {
          colors: {
            primary: "#145343",
            secondary: "#548A7F",
            accent: "#A3CEBF",
            background: "#E8F1F2",
            surface: "#ffffff",
            error: "#FF5252",
            info: "#2196F3",
            success: "#4CAF50",
            warning: "#FB8C00",
            dark: "#0C1411",
            "due-today": "#FF5722",
            'late': "#D50000",
            "empty-room": "#03A9F4",
            "no-payment": "#757575",
            "need-cleaning": "#9C27B0",
            "on-due-today": "#FFFFFF", // Assuming white text on 'due-today' background
            "on-late": "#FFFFFF", // White text on 'late' background
            "on-empty-room": "#FFFFFF", // White text on 'empty-room' background
            "on-no-payment": "#FFFFFF", // White text on 'no-payment' background
          },
        },
      },
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
