<template>
  <v-container>
    <v-row>
      <v-col cols="auto"><h1>Manajemen Pengguna</h1></v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="ml-auto">
        <v-btn
          color="primary"
          prepend-icon="mdi-plus"
          variant="flat"
          @click="openAddDialog"
          size="large"
        >
          Tambah Pengguna
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- Display room data -->
        <v-data-table-server
          :headers="headers"
          :items="items.value"
          :items-length="items.totalData"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :page="currentPage"
          @update:page="handlePageChange"
          @update:items-per-page="handleItemsPerPageChange"
          class="elevation-1"
        >
          <template v-slot:[`item.index`]="{ index }">
            {{ (currentPage - 1) * itemsPerPage + index + 1 }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              append-icon="mdi-delete"
              @click="deleteItem(item)"
              color="red"
              >Hapus</v-btn
            >
            <v-btn
              class="ml-2"
              append-icon="mdi-pencil"
              @click="openEditDialog(item)"
              color="green"
              >Ubah</v-btn
            >
          </template>
        </v-data-table-server>
      </v-col>
    </v-row>

    <!-- Add/Edit Dialog -->
    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>
          <v-row>
            <div class="pa-4">
              <span v-if="isEditMode">Edit Pengguna</span>
              <span v-else>Tambah Pengguna</span>
            </div>
            <v-spacer />
            <v-btn
              class="pa-4"
              icon
              color="primary"
              @click="dialog = !dialog"
              aria-label="Close"
              variant="plain"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation v-model="validUserForm">
          <!-- Form Fields for Add/Edit -->
          <v-text-field
            v-model="editedItem.name"
            label="Nama"
            variant="outlined"
            :rules="[requiredRule]"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.username"
            variant="outlined"
            label="Username"
            :rules="[requiredRule]"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.email"
            label="Email"
            variant="outlined"
            :rules="[requiredRule, isEmail]"
          ></v-text-field>
          <v-select
            v-model="editedItem.roles"
            :items="['owner', 'receptionist']"
            label="Pilih Role"
            clearable
            variant="outlined"
            :rules="[isRequiredSelect]"
          ></v-select>
          <v-text-field
            v-model="editedItem.password"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            append-inner-icon="mdi-eye"
            variant="outlined"
            @click:append-inner="showPassword = !showPassword"
            :rules="[requiredRule, minLength(8)]"
          ></v-text-field>
          <v-text-field
            v-model="editedItem.confirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            label="Confirm Password"
            append-inner-icon="mdi-eye"
            variant="outlined"
            @click:append-inner="showConfirmPassword = !showConfirmPassword"
            :rules="[requiredRule, confirmPasswordRules]"
          ></v-text-field>
          <v-btn
            color="primary"
            @click="saveItem"
            block
            variant="outlined"
            size="large"
            :disabled="!validUserForm"
          >Simpan</v-btn
          >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { useStore } from "vuex";
import apiService from "@/services/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      rooms: [],
      validUserForm: false,
      itemsPerPage: 10,
      currentPage: 1,
      headers: [
        { title: '#', value: 'index', sortable: false },  // Index column
        { title: "Nama", value: "name" },
        { title: "Username", value: "username" },
        { title: "Email", value: "email" },
        { title: "Role", value: "roles" },
        { title: "Actions", value: "actions", sortable: false }, // Column for actions
      ],
      items: {
        totalData: 0,
      },
      dialog: false,
      isEditMode: false,
      showPassword: false,
      showConfirmPassword: false,
      editedItem: {
        id: null,
        name: "",
        email: "",
        username: "",
        roles: "",
        password: "",
        confirmPassword:"",
      },
    };
  },
  setup() {
    const store = useStore(); // Use store inside setup
    return { store };
  },
  methods: {
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.fetchUsers();
    },
    handleItemsPerPageChange(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage;
      this.fetchUsers();
    },
    async fetchUsers() {
      try {
        const response = await apiService.get("/users/all", {
          params: {
            sort: "id",
            sortBy: "asc",
          },
        });
        if (response.data.responseCode === "00" && response.data.responseData) {
          // Populate the items for the table
          this.items = response.data.responseData;
        }
      } catch (error) {
        console.error("Error fetching residents:", error);
      }
    },
    openAddDialog() {
      this.editedItem = {
        id: null,
        name: "",
        email: "",
        username: "",
        roles: "",
      };
      this.dialog = true;
    },
    openEditDialog(item) {
      this.editedItem = {
        id: item.id,
        name: item.name,
        email: item.email,
        username: item.username,
        roles: item.roles,
      };
      this.isEditMode = true;
      this.dialog = true;
    },
    async saveItem() {
      const requestBody = {
        username: this.editedItem.username,
        email: this.editedItem.email,
        name: this.editedItem.name,
        roles: this.editedItem.roles,
        password: this.editedItem.password,
      };

      try {
        let response;
        if (this.isEditMode) {
          response = await apiService.put(
              `/users/${this.editedItem.id}`,
              requestBody
          );
        } else {
          response =  await apiService.post("/users", requestBody);
        }

        if (response.data.responseCode === "00") {
          this.store.dispatch("notification/showNotification", {
            message: `Data berhasil disimpan!`,
            type: "success",
          });
          this.fetchUsers(); // Refresh the resident list
          this.dialog = false;
        } else {
          this.store.dispatch("notification/showNotification", {
            message: `Data gagal disimpan ${response.data.responseMessage}`,
            type: "error",
          });
          this.dialog = false;
        }
      } catch (error) {
        this.store.dispatch("notification/showNotification", {
          message: `Data gagal disimpan ${error}`,
          type: "error",
        });
      }
    },
    async deleteItem(item) {
      Swal.fire({
        title: "Hapus Pengguna",
        text: "Apakah Anda yakin mau menghapus pengguna ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Perform the delete action or API call here

          //        this.items = this.items.filter(i => i.id !== item.id);
          const response = await apiService.delete(`/users/${item.id}`);

          try {
            if (response.data.responseCode === "00") {
              this.store.dispatch("notification/showNotification", {
                message: `Data berhasil dihapus!`,
                type: "success",
              });
              this.fetchUsers();
            } else {
              this.store.dispatch("notification/showNotification", {
                message: `Data gagal dihapus! ${response.data.responseMessage}`,
                type: "error",
              });
              Swal.fire("Terdapat error sistem", "-", "error");
            }
          } catch (error) {
            this.store.dispatch("notification/showNotification", {
              message: `Data gagal dihapus`,
              error,
              type: "error",
            });
            Swal.fire("Terdapat error sistem!", error, "error");
          }
        }
      });
    },
  },
  computed: {
    confirmPasswordRules() {
      return () => 
        this.editedItem.password === this.editedItem.confirmPassword || "Password harus sesuai"
    }
  },
  created() {
    this.fetchUsers();
  },
};
</script>
