<template>
  <v-navigation-drawer app width="280" v-model="internalDrawerStatus">
    <v-list dense>
      <v-list-item>
        <div
          style="
            max-height: 200px;
            margin-top: 32px;
            margin-left: 24px;
            margin-bottom: 32px;
          "
        >
        <!-- :src="GlobalSettings.appLogo" -->
          <v-img            
            :src="require('@/assets/logo.png')"
            height="200px"
            width="auto"
            max-height="200px"
          ></v-img>
        </div>
      </v-list-item>
      <v-list-item
        v-for="item in filteredMenuItems"
        :key="item.title"
        :to="item.route"
        :prepend-icon="item.icon"
        link
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
  
  
  <script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  props: {
    drawerStatus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      drawerItems: this.drawerStatus,
      menuItems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "/",
          allowedRoles: ["owner", "receptionist"],
        },
        {
          title: "Manajemen Unit",
          icon: "mdi-bed",
          route: "/manajemen-kamar",
          allowedRoles: ["owner", "receptionist"],
        },
        {
          title: "Manajemen Penghuni",
          icon: "mdi-clipboard-account",
          route: "/manajemen-penghuni",
          allowedRoles: ["owner", "receptionist"],
        },
        {
          title: "Manajemen Pengeluaran",
          icon: "mdi-cash-multiple",
          route: "/manajemen-pengeluaran",
          allowedRoles: ["owner"],
        },
        {
          title: "Laporan",
          icon: "mdi-file-chart",
          route: "/laporan",
          allowedRoles: ["owner"],
        },
        {
          title: "Manajemen Pengguna",
          icon: "mdi-account-group",
          route: "/manajemen-pengguna",
          allowedRoles: ["owner"],
        },
      ],
    };
  },
  emits: ["update:drawerStatus"],
  computed: {
    ...mapGetters(["userRoles"]),
    filteredMenuItems() {
      return this.menuItems.filter((item) =>
        item.allowedRoles.some((role) => this.userRoles.includes(role))
      );
    },
    internalDrawerStatus: {
      get() {
        return this.drawerStatus;
      },
      set(value) {
        this.$emit("update:drawerStatus", value);
      },
    },
    ...mapState(["Notification", "GlobalSettings", "User"]),

  },
};
</script>
  
  <style scoped>
.v-list-item {
  align-items: center;
}
</style>
  
  