<template>
  <v-container>
    <v-card class="card-with-spacing mb-6">
      <v-card-title class="card-title"
        >Detail Unit {{ room_number }}
      </v-card-title>
      <v-card-text>
        <v-row class="pt-4">
          <v-col cols="12" md="6">
            <!-- Room Status -->
            <p class="gray">Status Unit</p>
            <p class="text-h6">{{ room_status || "-" }}</p>
          </v-col>
        </v-row>

        <!-- Divider for Form Inputs -->
        <v-divider class="my-4"></v-divider>
        <v-form ref="roomForm" lazy-validation v-model="validEditForm">
          <v-row>
            <v-col cols="12" md="6">
              <p class="pb-2">Nomor Unit</p>
              <v-text-field
                v-model="room_number"
                :readonly="!isEditing"
                variant="outlined"
                :rules="[requiredRule]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <p class="pb-2">Harga Unit</p>
              <v-text-field
                v-model="room_price"
                :readonly="!isEditing"
                variant="outlined"
                :rules="[requiredRule, isPositiveNumber]"
              ></v-text-field>
            </v-col>
            <!-- Room Type -->
            <v-col cols="12" md="6">
              <p class="pb-2">Tipe Unit</p>

              <v-select
                v-model="room_type"
                :items="['Superior', 'Superior Plus', 'Loft Balkon', 'Loft Non Balkon', 'VIP']"
                :readonly="!isEditing"
                :rules="[isRequiredSelect]"
                variant="outlined"
              ></v-select>
            </v-col>

            <!-- Room Floor -->
            <v-col cols="12" md="6">
              <p class="pb-2">Lantai</p>
              <v-select
                v-model="room_floor"
                :items="[1, 2, 3]"
                :readonly="!isEditing"
                variant="outlined"
              ></v-select>
            </v-col>
          </v-row>

          <v-card-actions v-if="userRoles.includes('owner')">
            <v-spacer />
            <v-btn
              color="primary"
              @click="toggleEdit"
              variant="flat"
              width="100px"
              size="large"
              >{{ isEditing ? "Batal" : "Ubah" }}
            </v-btn>
            <v-btn
              v-if="isEditing"
              color="success"
              @click="saveData"
              variant="flat"
              width="100px"
              size="large"
              :disabled="!validEditForm"
              >Simpan
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="card-with-spacing">
      <v-card-title class="card-title">Detail Penghuni Unit</v-card-title>
      <template v-if="tenant_name">
        <v-card-text>
          <v-row class="pt-4">
            <v-col cols="12" md="6">
              <!-- Join Date -->
              <p color="gray">Nama Penghuni</p>
              <p class="text-h6">{{ tenant_name || "-" }}</p>

              <p color="gray">Nomor Telepon</p>
              <p class="text-h6">{{ phoneNumber || "-" }}</p>

              <p color="gray">Tanggal Join</p>
              <p class="text-h6">{{ room_join_date || "-" }}</p>

              <!-- Due Date -->
            </v-col>
            <v-col cols="12" md="6">
              <!-- Last Cleaning Date -->
              <p class="gray">Tanggal Jatuh Tempo</p>
              <p class="text-h6">{{ room_duedate || "-" }}</p>

              <!-- Last Payment Date -->
              <p class="gray">Tanggal Pembayaran Terakhir</p>
              <p class="text-h6">{{ room_last_paymentdate || "-" }}</p>

              <p class="gray">Tanggal Pembersihan Unit Terakhir</p>
              <p class="text-h6">{{ room_cleaning_date || "-" }}</p>

              <!-- Next Cleaning Date -->
              <p class="gray">Tanggal Pembersihan Unit Selanjutnya</p>
              <p class="text-h6">{{ room_next_cleaning_date || "-" }}</p>
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col cols="12">
              <p class="gray pb-2">Foto Identitas</p>
              <v-img :src="idPhoto" max-width="400"></v-img>
            </v-col>
          </v-row>
          <v-row></v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red"
            size="large"
            variant="flat"
            width="200px"
            @click="unassignResident"
          >
            Hapus penghuni
          </v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          <v-row class="pt-4">
            <v-col cols="12">
              <p color="gray">Nama Penghuni</p>

              <v-autocomplete
                v-model:search="searchQuery"
                @update:search="fetchResidents"
                v-model="selectedResident"
                :item-title="(item) => `${item.title} - ${item.idCardNo}`"
                :item-value="(item) => `${item.value}`"
                :items="residentNames"
                no-filter
                variant="outlined"
              >
              </v-autocomplete>
              <p color="gray">Tanggal Masuk</p>

              <v-text-field
                v-model="placeholderJoinDate"
                type="date"
                variant="outlined"
              ></v-text-field>

              <p color="gray">Deposit Unit</p>
              <CurrencyInput
                v-model="deposit"
                :options="{
                  currency: 'IDR',
                  locale: 'id-ID',
                  prefix: 'Rp ',
                  precision: 0,
                }"
                :rules="[requiredRule, isPositiveNumber]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />

          <v-row class="flex-wrap" justify="end" no-gutters>
            <v-col cols="12" sm="auto" class="mb-2 mb-sm-0 mr-sm-2">
              <v-btn
                @click="goToResidentPage"
                size="large"
                block
                variant="flat"
                color="primary"
                class="pa-3"
              >
                Tambah Penghuni Baru
              </v-btn>
            </v-col>

            <v-col cols="12" sm="auto" class="mb-2 mb-sm-0">
              <v-btn
                @click="assignResident"
                size="large"
                block
                variant="flat"
                color="primary"
                class="pa-3"
              >
                Assign Penghuni
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import apiService from "@/services/api";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CurrencyInput from "@/components/CurrencyInput.vue";

export default {
  components: {
    CurrencyInput,
  },
  name: "DetailEditKamar",
  props: {
    roomId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: "", // To hold the search input
      residentNames: [], // To hold the list of resident names from the API
      selectedResident: null, // To hold the selected resident name
      isEditing: false,
      room_number: null,
      room_status: null, // Changed to match v-select options
      room_price: null,
      room_floor: null, // Number is okay here for floor selection
      room_type: null,
      room_duedate: null, // Proper date format
      room_last_paymentdate: null,
      room_cleaning_date: null,
      room_next_cleaning_date: null,
      room_join_date: null,
      tenant_name: null,
      room_deposit: null,
      phoneNumber: null,
      placeholderJoinDate: dayjs().format("YYYY-MM-DD"),
      idPhoto: null,
      validEditForm: false,
      deposit: 0,
    };
  },
  setup() {
    const store = useStore(); // Use store inside setup
    return { store };
  },
  methods: {
    async unassignResident() {
      Swal.fire({
        title: "Hapus Penghuni",
        text: "Apakah Anda yakin mau menghapus penghuni ini?",
        input: "number",
        inputLabel: "Masukkan jumlah deposit yang dikembalikan",
        inputValue: Math.floor(this.room_deposit),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        preConfirm: (value) => {
          if (!value || value <= 0) {
            Swal.showValidationMessage("Jumlah deposit harus dimasukkan!");
            return false;
          }
          return value;
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await apiService.post(
              `/occupancies/unassign/${this.roomId}`,
              {
                params: {
                  refundAmount: result.value,
                },
              }
            );
            if (response.data.occupancyStatus === "Ended") {
              this.store.dispatch("notification/showNotification", {
                message: "Berhasil menghapus penghuni dari unit",
                type: "success",
              });
              this.tenant_name = null;
            } else {
              this.store.dispatch("notification/showNotification", {
                message: `Gagal menghapus penghuni dari unit: ${response.data.responseMessage}`,
                type: "error",
              });
            }
          } catch (error) {
            this.store.dispatch("notification/showNotification", {
              message: `Gagal menghapus penghuni dari unit: ${error}`,
              type: "error",
            });
          }
        }
      });
    },
    async fetchResidents() {
      try {
        const response = await apiService.post(
          "/residents/filter",
          this.searchQuery
            ? [
                {
                  name: "",
                  operation: "INNER_FILTER_OR",
                  subFilter: [
                    {
                      name: "name",
                      operation: "CONTAIN",
                      value: this.searchQuery,
                    },
                    {
                      name: "idCardNo",
                      operation: "CONTAIN",
                      value: this.searchQuery,
                    },
                  ],
                },
              ]
            : [],
          {
            params: {
              sort: "name",
              sortBy: "asc",
            },
          }
        );

        if (response.data.responseCode === "00") {
          this.residentNames = response.data.responseData.value.map(
            (resident) => ({
              title: resident.name,
              value: resident.id,
              idCardNo: resident.idCardNo,
            })
          );
        } else {
          console.error("Error fetching residents");
        }
      } catch (error) {
        console.error("API error:", error);
      }
    },

    onResidentSelected(residentId) {
      this.selectedResident = residentId;
    },
    async assignResident() {
      if (!this.selectedResident) {
        alert("Please select a resident to assign");
        return;
      }

      const requestBody = {
        roomId: this.roomId,
        residentId: this.selectedResident,
        joinDate: this.placeholderJoinDate || dayjs().format("YYYY-MM-DD"), // Set the current date for joinDate
        notes: "",
        depositAmount: this.deposit,
      };

      try {
        const response = await apiService.post(
          "/occupancies/assign",
          requestBody
        );

        if (response.data.occupancyStatus === "Active") {
          this.store.dispatch("notification/showNotification", {
            message: "Berhasil menambahkan penghuni",
            type: "success",
          });
          this.tenant_name = this.residentNames.find(
            (resident) => resident.value === this.selectedResident
          ).title;
          // console.log("tenant name", this.tenant_name, "extract text", this.residentNames.find(resident => resident.value === this.selectedResident).text);
        } else {
          console.error(
            "Error assigning resident:",
            response.data.responseMessage
          );
          this.store.dispatch("notification/showNotification", {
            message: `Error menambahkan penghuni : ${response.data.responseMessage}`,
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error assigning resident:", error);
        this.store.dispatch("notification/showNotification", {
          message: `Error menambahkan penghuni : ${error}`,
          type: "error",
        });
      }
    },
    goToResidentPage() {
      this.$router.push({ name: "ManajemenPenghuni" }); // Replace with your actual resident page route
      // You can also trigger the 'add resident' dialog automatically here if needed
    },

    async fetchRoomDetail() {
      try {
        const response = await apiService.get(
          `/rooms/filter-first/id/${this.roomId}`,
          {
            params: {
              sort: "roomNumber",
              sortBy: "asc",
            },
          }
        );
        if (response.data.responseCode === "00" && response.data.responseData) {
          const rooms = response.data.responseData;

          this.room_number = rooms.roomNumber;
          this.room_status = rooms.status;
          this.room_type = rooms.roomType;
          this.room_floor = rooms.floor;
          this.room_price = rooms.pricePerMonth;
          this.room_duedate = rooms.roomResident?.dueDate;
          this.room_last_paymentdate = rooms.roomResident?.lastPaymentDate;
          this.room_cleaning_date = rooms.roomResident.lastCleaningDate;
          this.room_next_cleaning_date = rooms.roomResident.nextCleaningDate;
          this.room_join_date = rooms.roomResident.joinDate;
          this.tenant_name = rooms.roomResident?.resident?.name;
          this.phoneNumber = rooms.roomResident?.resident?.phoneNumber;
          this.idPhoto = rooms.roomResident?.resident?.idImage;
          this.room_deposit = rooms.roomResident?.depositAmount;
        } else {
          const errorMessage =
            response.data.responseMessage || "Error fetching rooms data";
          throw new Error(errorMessage);
        }
      } catch (error) {
        console.error("Error fetching rooms data:", error);
      }
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    async saveData() {
      if (await this.$refs.roomForm.validate()) {
        const requestBody = {
          roomNumber: this.room_number,
          roomType: this.room_type,
          floor: this.room_floor,
          status: this.room_status,
          pricePerMonth: this.room_price,
          description: null,
          amenities: null,
        };
        try {
          const response = await apiService.put(
            `rooms/${this.roomId}`,
            requestBody
          );

          if (response.data.responseCode === "00") {
            this.store.dispatch("notification/showNotification", {
              message: `Berhasil ubah data unit!`,
              type: "success",
            });
            this.fetchRoomDetail();
          } else {
            this.store.dispatch("notification/showNotification", {
              message: `Gagal ubah data unit : ${response.data.responseMessage}`,
              type: "error",
            });
          }
        } catch (error) {
          this.store.dispatch("notification/showNotification", {
            message: `Gagal ubah data unit : ${error}`,
            type: "error",
          });
        }

        this.isEditing = false;
      } else {
        // Form is invalid, show errors
        Swal.fire({
          title: "Error",
          text: "Silakan lengkapi data terlebih dahulu",
          icon: "error",
          confirmButtonText: "Tutup",
        });
      }
    },
  },
  created() {
    this.fetchResidents();
    this.fetchRoomDetail();
  },
  computed: {
    ...mapGetters(["userRoles"]),
  },
};
</script>

<style scoped>
.card-title {
  background-color: rgb(var(--v-theme-primary));
  color: rgb(var(--v-theme-on-due-today));
}
</style>
