<template>
  <v-container>
    <v-row>
      <v-col cols="auto">
        <h1>Dashboard</h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <!-- First row of 3 cards -->
      <v-col
        v-for="(card, index) in cards"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="4"
      >
        <room-card
          :filters="card.filters"
          :title="card.title"
          :color="card.color"
          :icon="card.icon"
          @click="fetchRoomDetail(card)"
        />
      </v-col>
    </v-row>
  </v-container>
    <!-- Room List Dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="headline">Daftar Unit</v-card-title>

        <!-- List of rooms -->
        <v-list dense>
          <v-list-item v-for="room in roomList" :key="room.id">
            <v-list-item-content>
              <v-list-item-title>
                <strong>Unit {{ room.roomNumber }}</strong> {{ room?.roomResident ? ' - ' : '' }} {{ room?.roomResident?.resident?.name }}
              </v-list-item-title>
              <v-list-item v-if="room?.roomResident">
                <div>Telepon: {{ room?.roomResident?.resident?.phoneNumber }}</div>
                <div>Jatuh Tempo: {{ room?.roomResident?.dueDate ? formatDate(room.roomResident.dueDate) : 'Tidak ada data' }}</div>
                <div>Pembersihan terakhir: {{ room?.roomResident?.lastCleaningDate ? formatDate(room.roomResident.lastCleaningDate) : 'Tidak ada data' }}</div>
                <div v-if="isBedSheetChange(room?.roomResident?.nextBedSheetChange)">  Sprei :    </div>                
              </v-list-item>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- Dialog actions -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false" variant="outlined" block>Keluar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
  
<script>
import RoomCard from "@/components/RoomCard.vue";
import * as dayjs from "dayjs"
import apiService from "@/services/api";
export default {
  name: "DashboardView",
  components: { RoomCard },
  data() {
    return {
      dialog: false,
      roomList: [],
      cards: [
        {
          color: "success",
          title: "Unit Terisi",
          icon: `mdi-bed-double-outline`,
          filters: [
            {
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            },
          ],
        },
        {
          color: "empty-room",
          title: "Unit Kosong",
          icon: `mdi-door`,
          filters: [
            {
              name: "status",
              operation: "EQUAL",
              value: "Vacant",
            },
          ],
        },
        {
          color: "warning",
          title: "Hampir Jatuh Tempo",
          icon: `mdi-alert-circle-outline`,
          filters: [
            {
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            },
            {
              name: "roomResident.dueDate",
              operation: "LESS_THAN",
              value: dayjs().add(3, 'days').format('YYYY-MM-DD'),
            },
            {
              name: "roomResident.dueDate",
              operation: "GREATER_THAN",
              value: dayjs().format('YYYY-MM-DD'),
            }
          ],
        },
        {
          color: "due-today",
          title: "Jatuh Tempo",
          icon: `mdi-calendar-range`,
          filters: [
            {
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            },
            {
              name: "roomResident.dueDate",
              operation: "EQUAL",
              value: dayjs().format('YYYY-MM-DD'),
            },
          ],
        },
        {
          color: "late",
          title: "Telat Bayar",
          icon: `mdi-close-octagon`,
          filters: [
            {
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            },
            {
              name: "roomResident.dueDate",
              operation: "LESS_THAN",
              value: dayjs().format('YYYY-MM-DD'),
            },
          ],
        },
        {
          color: "secondary",
          title: "Unit Harus Dibersihkan",
          icon: `mdi-broom`,
          filters: [
            {
              name: "status",
              operation: "EQUAL",
              value: "Occupied",
            },
            {
              name: "roomResident.nextCleaningDate",
              operation: "EQUAL",
              value: new dayjs().format('YYYY-MM-DD'),
            },
          ],
        },
      ],
    };
  },
  methods: {
    async fetchRoomDetail(card) {
      try {
        const response = await apiService.post("/rooms/filter", card.filters, {
          params: {
            page: 1,
            maxRowCount: 100,
            sort: "roomNumber",
            sortBy: "asc",
          },
        });
        this.roomList = response.data.responseData.value;
        console.log(this.roomList)
      } catch (error) {
        console.error("Error fetching rooms data:", error);
      }
      this.dialog = true;
    },
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    isBedSheetChange(date) {
      const today = dayjs().format('YYYY-MM-DD');
      return today === date;
    }
  }
};
</script>
